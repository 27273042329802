/* eslint react/no-array-index-key: 0 */
import React, { FunctionComponent } from 'react';
import { Box, Text, ButtonLink, Hyperlink } from 'mdlkit';
import styled from 'styled-components';

interface Props {
  onBack: () => void;
}

const MessageContainer = styled(Box)`
  display: flex;
  margin-top: 16px;
  padding: 12px;
`;

const BlueHyperlink = styled(Hyperlink)`
  color: ${({ theme }) => theme.colors.blue};
  white-space: nowrap;
`;

const SVGWrapper = styled.div`
  color: ${({ theme }) => theme.colors.blue};
  margin-right: 16px;
  margin-top: 4px;
`;

const InfoBadge = () => {
  return (
    <SVGWrapper>
      <svg
        style={{ flexShrink: 0 }}
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10" />
        <path d="M12 16v-4" />
        <path d="M12 8h.01" />
      </svg>
    </SVGWrapper>
  );
};

const NoPrimaryAccountFound: FunctionComponent<Props> = ({ onBack }: Props) => {
  return (
    <>
      <MessageContainer>
        <InfoBadge />
        <div>
          <Text align="left" margin="0" fontSize={2}>
            To access a minor dependent’s account, please log in as the primary
            account holder.
          </Text>
          <Text align="left" margin="24px 0 0 0" fontSize={1}>
            If you need help, please contact MD Live Customer Service at{' '}
            <BlueHyperlink href="tel:1-800-400-6354">
              (800) 400 MDLIVE
            </BlueHyperlink>
          </Text>
        </div>
      </MessageContainer>
      <Box textAlign="center" mt={30}>
        <ButtonLink onClick={onBack}>Back</ButtonLink>
      </Box>
    </>
  );
};

export default NoPrimaryAccountFound;
